import React from 'react';
import { Button } from 'zent';
import { FormattedMessage } from 'react-intl';
import { checkIsGoodSoldOut } from 'helper/goods';
import { GOODS_STATUS_SELLING, GOODS_FEATURE_TYPE, ECARD_GOODS_STATUS_STR } from 'const/index';
import { HtPrice } from 'cpn/goods-price';
import { ActivityStatusType } from 'const/group-buying';
import { getTotalAttachPropsPrice } from 'cpn/sku-list/additional-items/utils';

import { Style } from './style';
import { useRequireLogin } from 'cpn/require-login-wrapper';

export const GoodsBuyButton = ({ goodsDetail, onGroupBuying, currency, cart }) => {
  const groupActivityDescription = goodsDetail?.groupActivityDescription ?? {};
  const activityStatus = goodsDetail?.activityStatus;
  const isExpired = activityStatus === ActivityStatusType.Expired; // 获取是否过期
  const originPrice = cart?.variantId ? cart.compareAtPrice : goodsDetail?.compareAtPrice; // 原价
  const curPrice = cart?.variantId ? cart.price : goodsDetail?.minPrice; // 拼团价
  const quantity = cart?.quantity || 0;
  // 是否为服务商品
  const isServiceGoods = goodsDetail?.goodsFeatureType === GOODS_FEATURE_TYPE.newService;
  // 是否为卡券商品
  const isECardGoods = goodsDetail?.goodsFeatureType === GOODS_FEATURE_TYPE.eCard;
  // 卡券商品是否过期
  const isECardExpired = isECardGoods && goodsDetail?.goodsSoldStatusStr === ECARD_GOODS_STATUS_STR.expired;
  // 是否售罄
  const isSoldOut =
    checkIsGoodSoldOut(goodsDetail.variants) ||
    goodsDetail?.goodsStatus === GOODS_STATUS_SELLING.DOWN ||
    isECardExpired;
  const currentSkuItem = cart?.variantId
    ? goodsDetail?.variants?.find((v) => v.variantId === cart?.variantId)
    : goodsDetail?.variants?.[0]; // 当前选中的 sku
  // 最小起购数是否超出库存数
  const isExceedMinPurchase =
    goodsDetail?.minPurchaseQty > goodsDetail?.quantity ||
    (currentSkuItem && goodsDetail?.minPurchaseQty > currentSkuItem.quantity);

  const { checkedRequireLogin } = useRequireLogin();

  const onBuyAlone = () => {
    window.location.href = `https://${window.location.host}/products/${goodsDetail?.handle}`;
  };

  const onGroupBuy = () => {
    const activityId = groupActivityDescription?.activityId;
    onGroupBuying({
      activityId,
    });
  };
  const attachPropsPrice = getTotalAttachPropsPrice(cart.goodsAdditionalItems);
  // 服务商品暂不支持
  if (isServiceGoods) {
    return (
      <div className='goods-buy-button'>
        <Button type='primary' className='goods-buy-button-2 operate-button only-one-button' disabled>
          <div className='guide-text'>
            <FormattedMessage id='9798e8f7f61b4de19e4fd5bfb80af4f5' defaultMessage='暂不支持预订' />
          </div>
        </Button>
      </div>
    );
  }

  return (
    <Style>
      {/* 活动没过期且商品有库存 */}
      {!isExpired && !isSoldOut && !isExceedMinPurchase && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button' onClick={checkedRequireLogin(onBuyAlone)}>
            <div className='guide-text'>
              <FormattedMessage id='614e16cbb6744408af33ffb41ca17a66' defaultMessage='单独购买' />
            </div>
            <HtPrice price={(originPrice + attachPropsPrice) * quantity} currency={currency} />
          </Button>

          <Button
            type='primary'
            className='theme-primary goods-buy-button-2 operate-button'
            onClick={checkedRequireLogin(onGroupBuy)}
          >
            <div className='guide-text'>
              <FormattedMessage id='f0b04aadc77040e69b644ef1d3e2c0ca' defaultMessage='组团购买' />
            </div>
            <HtPrice price={(curPrice + attachPropsPrice) * quantity} currency={currency} />
          </Button>
        </div>
      )}

      {/* 活动过期了 */}
      {isExpired && !isSoldOut && !isExceedMinPurchase && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button only-one-button' onClick={checkedRequireLogin(onBuyAlone)}>
            <div className='guide-text'>
              <FormattedMessage id='614e16cbb6744408af33ffb41ca17a66' defaultMessage='单独购买' />
              <HtPrice price={(originPrice + attachPropsPrice) * quantity} currency={currency} />
            </div>
          </Button>
        </div>
      )}

      {/* 商品售罄 */}
      {(isSoldOut || isExceedMinPurchase) && (
        <div className='goods-buy-button'>
          <Button type='primary' className='goods-buy-button-2 operate-button only-one-button' disabled>
            <div className='guide-text'>
              {isExceedMinPurchase ? (
                <FormattedMessage id='goods.none.enough_store' defaultMessage='库存不足' />
              ) : (
                <FormattedMessage id='goods.sold_out' defaultMessage='售罄' />
              )}
            </div>
          </Button>
        </div>
      )}
    </Style>
  );
};
