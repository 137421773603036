import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberInput } from 'zent';
// import { ShareButtons } from 'cpn/share-buttons';
import SkuList from 'cpn/sku-list';
import { GoodsBuyButton } from '../goods-button';
import { GroupBuyingEntry } from '../group-buying-entry';
import { GroupDesc } from '../../../components/group-desc';
import { GoodsVendor } from 'components/page/goods-detail/goods-vendor';
import { GoodsTitle } from 'components/page/goods-detail/goods-title';
import { ServiceSupport } from 'components/page/goods-detail/service-support';
import { GroupRule } from 'components/page/group-buying/group-rule';
import { ShareButtons } from 'components/page/share-buttons';
import { ActivityStatus } from 'const/group-buying';
import { ReviewsBar } from 'components/page/goods-detail/goods-layout/reviews/review-bar';
import { Wish } from 'components/page/goods-detail/wish';
import { POSTER_TYPE } from '../../../../../../server/constants/poster';
import { useShareLink } from '../../hooks/index';
import { PickupInfo } from 'components/page/goods-detail/pick-up-info';
import { useGoodsLimit } from 'hook/goods/use-goods-limit';
import { useI18n } from 'hook/intl';
import InputNumberStepper from 'components/base/input-number-stepper';
import { GOODS_FEATURE_TYPE } from 'const/index';
import UseOfRule from 'components/page/goods-detail/use-of-rule';
import { useGoodsLocation } from 'hook/goods/use-goods-location';
import { LocationInfo } from 'components/page/goods-detail/location-info';

import cn from 'classnames';
import { Style } from './style';

export const ProductView = (props) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);

  const {
    kdtId,
    currency,

    baseConfigInfo,
    socialShareData,

    goodsDetail = {},
    cartHook,
    wishHook,
    reviewHook,
    userInfo,
    history,
    activityId,
    goodsId,
    groupBuyingDetail,
  } = props;

  const { shareLinkUrl } = useShareLink({
    activityId,
    goodsId,
    kdtId,
  });
  const isGoodsSoldOut = !goodsDetail?.quantity;
  const isECardGoods = goodsDetail?.goodsFeatureType === GOODS_FEATURE_TYPE.eCard;

  const { customerId } = userInfo;
  const { cart, onNumChange, onGroupBuying, onSkuSelect, onGoodsAdditionalItemSelect, isInitNeedCheck } = cartHook;
  const { showReview, reviewOverview, reviewTotalCount } = reviewHook;

  const { wishActionLoading, wishStatus, toggleToWishList } = wishHook;

  const productNameSize = baseConfigInfo?.product_name_size;
  const showSales = baseConfigInfo?.show_sales;

  const { title, variants, withVariants, options = [], attachProps = [], limitInfo } = goodsDetail;

  // 起购, 限购提醒
  // noInventoryRestrictions 是否是无限库存
  const { limitTips, minGoodsNum, maxGoodsNum, onMinusEvent, onPlusEvent } = useGoodsLimit({
    intl,
    inventory: cart?.noInventoryRestrictions === 1 ? 9999 : 
      cart?.variantId ? cart?.inventory : goodsDetail?.quantity,
    minPurchaseQty: goodsDetail?.minPurchaseQty,
    limitInfo,
  });

  // 地点信息
  const {
    isPickupGoods,
    pickupLocation,
    locationList,
  } = useGoodsLocation({
    kdtId,
    goodsId: goodsDetail?.goodsId,
    isECardGoods,
  });

  return (
    <Style className={cn({ 'is-sold-out': isGoodsSoldOut })}>
      <GoodsTitle title={title} productNameSize={productNameSize} />
      <GoodsVendor vendor={goodsDetail?.vendor} />
      <div className='col-item'>
        <ReviewsBar
          reviewOverview={reviewOverview}
          reviewTotalCount={reviewTotalCount}
          soldNum={goodsDetail?.soldNum}
          showSales={showSales}
          showReview={showReview}
        />
        <Wish wishStatus={wishStatus} wishActionLoading={wishActionLoading} toggleToWishList={toggleToWishList} />
      </div>

      <GroupDesc goodsDetail={goodsDetail} currency={currency} cart={cart} />
      <SkuList
        cart={cart}
        options={options}
        variants={variants}
        attachProps={attachProps}
        onSkuSelect={onSkuSelect}
        withVariants={withVariants}
        isInitNeedCheck={isInitNeedCheck}
        onGoodsAdditionalItemSelect={onGoodsAdditionalItemSelect}
        isNeedDisabled={isGoodsSoldOut || groupBuyingDetail?.status === ActivityStatus.ActivityEnd}
      />

      <div className='sku-wrapper'>
        <span className='sku-title'>
          <FormattedMessage id='common_count' />
        </span>
        <div className='goods-sku-detail'>
          {/* 限制为整数 */}
          <InputNumberStepper
            value={cart.quantity}
            min={minGoodsNum}
            max={maxGoodsNum}
            onMinusEvent={onMinusEvent}
            onPlusEvent={onPlusEvent}
            disabled={isGoodsSoldOut || groupBuyingDetail.status === ActivityStatus.ActivityEnd}
            onChange={onNumChange}
          />
          {limitTips && <div className='limit-tips'>{limitTips}</div>}
        </div>
      </div>
      <GroupBuyingEntry
        goodsId={goodsDetail?.goodsId}
        customerId={customerId}
        currency={currency}
        goodsDetail={goodsDetail}
        onGroupBuying={onGroupBuying}
      />
      <GoodsBuyButton
        goodsDetail={goodsDetail}
        onGroupBuying={onGroupBuying}
        currency={currency}
        history={history}
        cart={cart}
      />
      <ShareButtons
        // 生成海报的参数
        postInfo={{
          goodsId,
          activityId,
          posterType: POSTER_TYPE.wxGoodsGroupBuying,
        }}
        shareThirdUrl={`group-buying/product?goodsId=${goodsId}&activityId=${activityId}`}
        copyUrl={shareLinkUrl}
        history={history}
        customClass='goods-detail-share-btns'
        socialShareData={socialShareData}
      />
      <div className='space'>
        <GroupRule />
      </div>
      {
        isECardGoods &&
          <>
            <div className='space'>
              <LocationInfo
                $fm={$fm}
                locationList={locationList}
              />
            </div>
            <div className='space'>
              <UseOfRule
                $fm={$fm}
                ecardExtra={goodsDetail?.goodsExtra?.ecardExtra}
              />
            </div>
          </>
      }
      {
        !isECardGoods && 
        <>
          {
            isPickupGoods &&
            <div className='space'>
              <PickupInfo goodsId={goodsDetail?.goodsId} pickupLocation={pickupLocation} />
            </div>
          }
          <div className='space'>
            <ServiceSupport />
          </div>
        </>
      }
    </Style>
  );
};
