import styled from 'styled-components';

export const GroupBuyingEntryStyle = styled.div`
  margin-top: ${(props) => props.theme.getSpace([16, 28])};
  padding: 16px 0;
  border-top: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  border-bottom: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  .group-buy-title {
    ${(props) => props.theme.getBaseSize([14, 16])};
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .group-buying-item + .group-buying-item {
    margin-top: ${(props) => props.theme.getSpace([4, 16])};
  }
`;
export const GroupBuyingItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0;
  border-bottom: 1px solid #f7f8fa;
  padding: ${(props) => props.theme.getSpace([4, 8])} 0;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  .left {
    display: flex;
    align-items: center;
    .profile-box-wrapper {
      margin-right: 8px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .group-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 32px !important;
      line-height: 32px !important;
      background: var(--color_button) !important;
      cursor: pointer;
      .group-buying-text {
        ${(props) => props.theme.getBaseSize(props.theme.h11)};
      }
      margin-right: 8px;
    }
  }

  .group-count {
    ${(props) => {
      return props.theme.getBaseSize(props.theme.h10);
    }};
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)};
  }
  .group-time {
    ${(props) => props.theme.getBaseSize([10, 12])};
    margin-top: 4px;
    line-height: 18px;
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
    display: flex;
    .end-in-text {
      margin-right: 6px;
      ${(props) => props.theme.getBaseSize(props.theme.h12)};
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)} !important;
    }
    .time-item {
      ${(props) => props.theme.getBaseSize(props.theme.h12)};
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)} !important;
    }
    .time-value {
      display: inline !important;
    }
    .rest-time {
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 750px) {
    .group-time {
      flex-direction: column !important;
    }
    .rest-time {
      line-height: 22px;
    }
    .dot {
      display: none;
    }
  }
`;
